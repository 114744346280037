import React from "react"
import { Container } from "../utils/utils"

const Page404 = () => {
  return (
    <div>
      <Container>
        <h1>404</h1>
        <h6>
          <span>Niestety szukana strona nie istnieje</span>
        </h6>
      </Container>
    </div>
  )
}
export default Page404
